import { resolvable }              from '@mathquis/modelx-resolvables';
import { BillableStatusReference } from 'Models/invoice/BillableStatusModel';
import BillableStatusModel         from 'Models/invoice/BillableStatusModel';
import BillableTypeModel           from 'Models/invoice/BillableTypeModel';
import BillingGroupModel           from 'Models/invoice/BillingGroupModel';
import model                       from 'decorators/model';
import { urn }                     from 'helpers/ModelDictionary';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import { computed }                from 'mobx';
import moment                      from 'moment';
import InvoicePrivateApiModel      from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'billable')
@model.staticLabel('Élément à facturer')
export default class BillableModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/billables/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'billableItems.billableItemMetas.reference': string;
		'billableItems.billableItemMetas.value': id;
		'billableMetas': id;
		'billableMetas.reference': string;
		'billableMetas.value': id;
		'billableStatus': id;
		'billableStatus.reference': BillableStatusReference;
		'billingGroup': id;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'refundedInvoices.invoiceRefunded': id;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	public get amountExclTax(): number {
		return this.get('amountExclTax', 0);
	}

	@resolvable(BillableStatusModel, { attributeName: 'billableStatus', cache: true })
	public declare billableStatus: BillableStatusModel;

	@resolvable(BillingGroupModel, { attributeName: 'billingGroup', cache: true })
	public declare billingGroup: BillingGroupModel;

	@computed
	public get date(): Moment {
		return moment(this.get('date', moment()));
	}

	public get description(): string {
		return this.get('description', '');
	}

	@resolvable(BillableTypeModel, { attributeName: 'billableType', cache: true })
	public declare billableType: BillableTypeModel;

	@computed
	public get invoiceableAt(): Moment {
		return moment(this.get('invoiceableAt', moment()));
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get billingGroupUrn(): string {
		return this.get('billingGroup.@urn', '');
	}
}
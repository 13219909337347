/* eslint-disable max-classes-per-file */

import { resolvable }              from '@mathquis/modelx-resolvables';
import InterventionStatusModel     from 'Models/intervention/InterventionStatusModel';
import InterventionTypeModel       from 'Models/intervention/InterventionTypeModel';
import InterventionVersionModel    from 'Models/intervention/InterventionVersionModel';
import TaskZoneModel               from 'Models/intervention/TaskZoneModel';
import PartitionAddressModel       from 'Models/partition/PartitionAddressModel';
import PartitionModel              from 'Models/partition/PartitionModel';
import model                       from 'decorators/model';
import reverseResolvable           from 'decorators/reverseResolvable';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import AbstractApiModel            from '../../abstracts/AbstractApiModel';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export interface ILocationModel extends AbstractApiModel {
	fullAddress: string;
	urn: Urn;
}

export class LocationEmptyModel extends AbstractApiModel implements ILocationModel {
	public get fullAddress(): string {
		return '';
	}
}

@model.staticLabel('Intervention')
@model.urn('$', 'intervention', 'intervention')
export default class InterventionModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/interventions/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'exists[note]': string;
		'exists[observations]': string;
		'interventionType': id;
		'locationUrn': string;
		'partitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'number': string;
	}> = {};

	public get comment(): string {
		return this.get('comment', '');
	}

	public get note(): string {
		return this.get('note', '');
	}

	public get number(): number {
		return this.get('number', 0);
	}

	public get interventionTypeId(): id {
		return this.get('interventionType.id', 0) || this.interventionType.id;
	}

	public get observations(): string {
		return this.get('observations', '');
	}

	public get inDeletionProcess(): boolean {
		return this.get('inDeletionProcess', false);
	}

	public get locationUrn(): string {
		return this.get('locationUrn', '');
	}

	public get label(): string {
		return this.get('label', '');
	}

	@resolvable(InterventionStatusModel, { attributeName: 'interventionStatus', cache: true })
	public declare interventionStatus: InterventionStatusModel;

	@resolvable(InterventionTypeModel, { attributeName: 'interventionType', cache: true })
	public declare interventionType: InterventionTypeModel;

	@resolvable(LocationEmptyModel, {
		allow: [TaskZoneModel, PartitionAddressModel],
		attributeName: 'locationUrn',
		unresolved: LocationEmptyModel,
	})
	public declare location: TaskZoneModel | PartitionAddressModel;

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	public declare partition: PartitionModel;

	@reverseResolvable<InterventionVersionModel, InterventionModel>({
		filters: models => ({
			'intervention': models.map(m => m.urn),
			'version.versionType.reference': 'real'
		}),
		mapping: async (m1, m2) => {
			return m1.id === m2.getId('intervention');
		},
		urn: '$:intervention:intervention_version',
	})
	public declare realInterventionVersion: InterventionVersionModel;
}

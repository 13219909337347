import { resolvable }             from '@mathquis/modelx-resolvables';
import BillingGroupModel          from 'Models/invoice/BillingGroupModel';
import BillingPeriodModel         from 'Models/invoice/BillingPeriodModel';
import CurrencyModel              from 'Models/invoice/CurrencyModel';
import InvoiceStatusModel         from 'Models/invoice/InvoiceStatusModel';
import { InvoiceTypeReference }   from 'Models/invoice/InvoiceTypeModel';
import InvoiceTypeModel           from 'Models/invoice/InvoiceTypeModel';
import { PaymentMethodReference } from 'Models/invoice/PaymentMethodModel';
import PaymentMethodModel         from 'Models/invoice/PaymentMethodModel';
import model                      from 'decorators/model';
import { urn }                    from 'helpers/ModelDictionary';
import { Timestampable }          from 'helpers/traits';
import { Blamable }               from 'helpers/traits';
import { computed }               from 'mobx';
import moment                     from 'moment';
import InvoicePrivateApiModel     from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('Facture', false)
@urn('$', 'invoice', 'invoice')
export default class InvoiceModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/invoices/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'amountExclTax[between]': string;
		'amountExclTax[gt]': string;
		'amountExclTax[gte]': string;
		'amountExclTax[lt]': string;
		'amountExclTax[lte]': string;
		'amount[between]': string;
		'amount[gt]': string;
		'amount[gte]': string;
		'amount[lt]': string;
		'amount[lte]': string;
		'billingGroup': id;
		'billingGroup.invoicingGroup.owner.clientUrn': string;
		'billingGroup.invoicingGroup.owner.contactUrn': string;
		'billingGroup.invoicingGroup.owner.generalClientUrn': string;
		'billingGroup.invoicingGroup.owner.partitionUrn': string;
		'billingPeriod': id;
		'billingPeriod.endDate': string;
		'billingPeriod.startDate': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'date[after]': string | Date;
		'date[before]': string | Date;
		'date[strictly_after]': string | Date;
		'date[strictly_before]': string | Date;
		'invoicePayments.payment': id;
		'invoiceStatus': id;
		'invoiceStatus.reference': string;
		'invoiceType': id;
		'invoiceType.reference': InvoiceTypeReference;
		'number[between]': string;
		'number[gt]': string;
		'number[gte]': string;
		'number[lt]': string;
		'number[lte]': string;
		'paymentMethod': id;
		'paymentMethod.reference': PaymentMethodReference;
		'refundedByBillables.billable': id;
		'refundedByInvoices.refund': id;
		'refundedInvoices.invoiceRefunded': id;
		'remainingAmount': id;
		'remainingAmount[between]': string;
		'remainingAmount[gt]': string;
		'remainingAmount[gte]': string;
		'remainingAmount[lt]': string;
		'remainingAmount[lte]': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'amount': string;
		'amountExclTax': string;
		'billingPeriod.startDate': string;
		'date': string;
		'denomination': string;
		'id': string;
		'invoiceStatus.reference': string;
		'reference': string;
		'remainingAmount': string;
		'updatedAt': string;
	}> = {};

	public get address(): string {
		return this.get('address', '');
	}

	public get addressBis(): string {
		return this.get('addressBis', '');
	}

	public get amount(): number {
		return this.get('amount', 0);
	}

	public get amountExclTax(): number {
		return this.get('amountExclTax', 0);
	}

	public get amountVat(): number {
		return this.get('amountVat', 0);
	}

	@resolvable(BillingGroupModel, { attributeName: 'billingGroup', cache: true })
	public declare billingGroup: BillingGroupModel;

	@resolvable(BillingPeriodModel, { attributeName: 'billingPeriod' })
	public declare billingPeriod: BillingPeriodModel;

	@resolvable(CurrencyModel, { attributeName: 'currency', cache: true })
	public declare currency: CurrencyModel;

	@resolvable(InvoiceStatusModel, { attributeName: 'invoiceStatus', cache: true })
	public declare invoiceStatus: InvoiceStatusModel;

	@resolvable(InvoiceTypeModel, { attributeName: 'invoiceType', cache: true })
	public declare invoiceType: InvoiceTypeModel;

	public get city(): string {
		return this.get('city', '');
	}

	public get country(): string {
		return this.get('country', '');
	}

	@computed
	public get date(): Moment {
		return moment(this.get('date', moment()));
	}

	public get denomination(): string {
		return this.get('denomination', '');
	}

	@computed
	public get dueDate(): Moment {
		return moment(this.get('dueDate', moment()));
	}

	public get number(): number {
		return this.get('number', 0);
	}

	@resolvable(PaymentMethodModel, { attributeName: 'paymentMethod', cache: true })
	public declare paymentMethod: PaymentMethodModel;

	public get reference(): string {
		return this.get('reference', '');
	}

	public get zipCode(): string {
		return this.get('zipCode', '');
	}

	@computed
	public get fullAddress(): string {
		return `${this.address} ${this.addressBis ? ` ${this.addressBis}` : ''}${this.zipCode} ${this.city} ${this.country}`;
	}

	public get denominationBis(): string {
		return this.get('denominationBis', '');
	}

	public get freeReference(): string {
		return this.get('freeReference', '');
	}

	public get remainingAmount(): number {
		return this.get('remainingAmount', 0);
	}

	public get billingGroupUrn(): string {
		return this.get('billingGroup.@urn', '');
	}

	public get amountInclTax(): number {
		return this.get('amountInclTax', 0);
	}
}
// eslint-disable-next-line max-classes-per-file
import { resolvable }              from '@mathquis/modelx-resolvables';
import StaffMemberModel            from 'Models/rh/StaffMemberModel';
import VehicleModel                from 'Models/vehicle/VehicleModel';
import { urn }                     from 'helpers/ModelDictionary';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import { getIdFromUrn }            from 'tools/UrnTools';
import AbstractApiModel            from '../../abstracts/AbstractApiModel';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export interface IResourceEntityModel extends AbstractApiModel {
	fullName: string;
	urn: Urn;
}

export class ResourceEntityEmptyModel extends AbstractApiModel implements IResourceEntityModel {
	public get fullName(): string {
		return '';
	}
}

@urn('$', 'intervention', 'owner_resource')
export default class OwnerResourceModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/owner_resources/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'entityUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	public get entityId(): id {
		return getIdFromUrn(this.get('entityUrn')) || 0;
	}

	@resolvable(ResourceEntityEmptyModel, {
		allow: [StaffMemberModel, VehicleModel],
		attributeName: 'entityUrn',
		unresolved: ResourceEntityEmptyModel,
	})
	public declare entity: StaffMemberModel | VehicleModel;
}
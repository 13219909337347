import { resolvable }         from '@mathquis/modelx-resolvables';
import CurrencyModel          from 'Models/invoice/CurrencyModel';
import OwnerModel             from 'Models/invoice/OwnerModel';
import PaymentMethodModel     from 'Models/invoice/PaymentMethodModel';
import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'invoicing_group')
export default class InvoicingGroupModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'owner': id;
		'owner.clientUrn': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	static get path(): string {
		return '/invoicing_groups/{?id}';
	}

	@resolvable(CurrencyModel, { attributeName: 'currency', cache: true })
	public declare currency: CurrencyModel;

	@resolvable(OwnerModel, { attributeName: 'owner', cache: true })
	public declare owner: OwnerModel;

	@resolvable(PaymentMethodModel, { attributeName: 'paymentMethod', cache: true })
	declare paymentMethod: PaymentMethodModel;

	public get address(): string {
		return this.get('address', '');
	}

	public get addressBis(): string {
		return this.get('addressBis', '');
	}

	public get city(): string {
		return this.get('city', '');
	}

	public get clientUrn(): string {
		return this.get('clientUrn', '');
	}

	public get country(): string {
		return this.get('country', '');
	}

	public get zipCode(): string {
		return this.get('zipCode', '');
	}

	public get fullAddress(): string {
		return `${this.title ? `(${this.title})` : ''} ${this.address} ${this.addressBis ? this.addressBis + ' ' : ''}${this.zipCode} ${this.city} ${this.country}`;
	}

	public get denomination(): string {
		return this.get('denomination', '');
	}

	public get denominationBis(): string {
		return this.get('denominationBis', '');
	}

	public get title(): string {
		return this.get('title', '');
	}
}